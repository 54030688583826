import { ReactNode } from "react"
import styled, {
  css,
  DefaultTheme,
  DefaultThemeColorKey,
  StyledComponentProps,
} from "styled-components"

export const Img = styled.img`
  width: 400px;
  /* height: 320px; */
  display: block;
  -webkit-user-drag: none;
`

type FlexBoxProperty = {
  justifyContents?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline"
  gap?: number
  column?: boolean
  wrap?: boolean
  width?: number | string
  height?: number | string
  minHeight?: string
  maxHeight?: string
  doNotShowScrollBar?: boolean
}

export const FlexBox = styled.div<FlexBoxProperty>`
  width: ${({ width }) =>
    width ? (typeof width === "string" ? width : `${width}px`) : `100%`};
  height: ${({ height }) =>
    height ? (typeof height === "string" ? height : `${height}px`) : `auto`};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 0)};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "none")};
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
  justify-content: ${({ justifyContents }) =>
    justifyContents ? justifyContents : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "stretch")};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  ${({ doNotShowScrollBar }) =>
    doNotShowScrollBar &&
    css`
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`

type WrapperProps = FlexBoxProperty & {
  children: ReactNode
  flex?: boolean
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent"
}

export const Wrapper = ({
  children,
  flex,
  justifyContents,
  alignItems,
  gap,
  column,
  wrap,
  width,
  height,
  textAlign,
}: WrapperProps) => (
  <>
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "1200px",
          margin: "0 auto",
          textAlign: `${textAlign ? textAlign : "match-parent"}`,
        }}
      >
        {flex ? (
          <FlexBox
            justifyContents={justifyContents}
            alignItems={alignItems}
            gap={gap}
            column={column}
            wrap={wrap}
            width={width}
            height={height}
          >
            {children}
          </FlexBox>
        ) : (
          children
        )}
      </div>
    </div>
  </>
)

type StyleText = {
  box?: boolean
  pointer?: boolean
  color?: DefaultThemeColorKey
}

export const Text = {
  H3: styled.span<StyleText>`
    font-weight: 500;
    font-size: 31px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H4: styled.span<StyleText>`
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 0.25px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,

  H4bold: styled.span<StyleText>`
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 0.25px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H5: styled.span<StyleText>`
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H5bold: styled.span<StyleText>`
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H6: styled.span<StyleText>`
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H7: styled.span<StyleText>`
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.15px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H7regular: styled.span<StyleText>`
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.15px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  H8: styled.span<StyleText>`
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.15px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Subtitle1: styled.span<StyleText>`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: 21.79px;
  `,
  Subtitle2: styled.span<StyleText>`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: 21.79px;
  `,
  Bodylight: styled.span<StyleText>`
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Body1: styled.span<StyleText>`
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Body1bold: styled.span<StyleText>`
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Body2: styled.span<StyleText>`
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: 1.5;
  `,
  Caption: styled.span<StyleText>`
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Caption2: styled.span<StyleText>`
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Caption3: styled.span<StyleText>`
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Caption4: styled.span<StyleText>`
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Overline: styled.span<StyleText>`
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Overline2: styled.span<StyleText>`
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Header: styled.span<StyleText>`
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Subtext: styled.span<StyleText>`
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Subtextbold: styled.span<StyleText>`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Menu: styled.span<StyleText>`
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0px;
    cursor: pointer;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Menubold: styled.span<StyleText>`
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Title: styled.span<StyleText>`
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Maintitle: styled.span<StyleText>`
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Sbodybold: styled.span<StyleText>`
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,

  Sbody: styled.span<StyleText>`
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,

  Maintext: styled.span<StyleText>`
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Maintextbold: styled.span<StyleText>`
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Superscript: styled.span<StyleText>`
    font-weight: 400;
    font-size: 8px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Number: styled.span<StyleText>`
    font-weight: 700;
    font-size: 9px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
  Numberlight: styled.span<StyleText>`
    font-weight: 400;
    font-size: 9px;
    letter-spacing: 0px;
    /* common */
    display: ${({ box }) => (box ? "block" : "inline")};
    cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
    color: ${({ color, theme }) =>
      color ? theme.color[color] : theme.color.light};
    height: fit-content;
    line-height: normal;
  `,
}

type StyleMargin = {
  size: number
  direction: "row" | "column"
}

export const Margin = styled.div<StyleMargin>`
  height: ${({ direction, size }) => (direction === "column" ? size : 0)}px;
  width: ${({ direction, size }) => (direction === "column" ? 0 : size)}px;
`

type StyleButton = {
  thema:
    | "black"
    | "black-and-red"
    | "red"
    | "red90"
    | "light"
    | "dark"
    | "gray80"
    | "gray90"
  bg?: string
  text?: string | ReactNode
  width?: string
  padding?: string
  cursor?: string
}

const ButtonBox = styled.button<StyleButton>`
  min-width: ${(props) => (props.width ? `${props.width}` : `117px`)};
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
  padding: ${({ padding }) => (padding ? padding : "7px 25px 7px 25px")};
  border: 1px solid
    ${({ thema }) =>
      thema === "gray80"
        ? "#434343"
        : thema === "gray90"
        ? "#303030"
        : thema === "red90"
        ? "#ff6600"
        : thema === "red"
        ? "#ff6600"
        : thema === "black"
        ? "#a3a3a3"
        : thema === "light"
        ? "#d3d3d3"
        : thema === "dark"
        ? "#343434"
        : "#d3d3d3"};

  background-color: ${({ thema, bg }) =>
    bg
      ? bg
      : thema === "gray90"
      ? "#303030"
      : thema === "gray80"
      ? "#434343"
      : thema === "red90"
      ? "#ff6600"
      : thema === "red"
      ? "#ff6600"
      : thema === "black"
      ? "#080808"
      : thema === "black-and-red"
      ? "#080808"
      : thema === "light"
      ? "#fafafa"
      : thema === "dark"
      ? "#191919"
      : "#d3d3d3"};
`

export const Button = (
  props: StyledComponentProps<"button", DefaultTheme, StyleButton, never>,
) => (
  <ButtonBox {...props}>
    <Text.Subtitle2
      color={props.thema === "light" ? "black" : "light"}
      pointer={props.cursor ? false : true}
      style={props.cursor ? { cursor: props.cursor } : {}}
    >
      {props.text}
    </Text.Subtitle2>
  </ButtonBox>
)

type StyleHover = {
  box?: boolean
}

export const Hover = styled.span<StyleHover>`
  display: ${({ box }) => (box ? "block" : "inline")};
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
